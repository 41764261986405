import { Box, Center, Container, Flex, Heading, Link, Stack, Text, useBreakpointValue } from '@chakra-ui/react'
import type { FC } from 'react'
import { Outlet } from 'react-router-dom'

import Wordmark from '@app/images/wordmark'

const SessionShell: FC = () => (
  <Flex flex="1" h="100vh">
    <Box flex="1" bg="colorPalette.emphasized" hideBelow="lg">
      <Box w="full" h="full">
        <Flex direction="column" h="full" px={{ base: '4', md: '8' }}>
          <Flex align="center" h="24">
            <Link href="https://www.doubleloop.app">
              <Wordmark boxSize={48} />
            </Link>
          </Flex>
          <Flex align="center" flex="1">
            <Stack gap="8">
              <Stack gap="6">
                <Heading size={useBreakpointValue({ md: 'lg', xl: '2xl' })}>Build your Value Architecture</Heading>
                <Text maxW="md" fontSize="lg" fontWeight="medium">
                  DoubleLoop is a platform for teams to discover what levers actually move your business. Find whats
                  working and get rid of everything that isn't.
                </Text>
              </Stack>
            </Stack>
          </Flex>
          <Flex align="center" h="24">
            <Text fontSize="sm">© {new Date().getFullYear()} DoubleLoop. All rights reserved.</Text>
          </Flex>
        </Flex>
      </Box>
    </Box>
    <Box flex="1.5" py={{ base: '24', md: '32' }}>
      <Center h="full">
        <Container maxW="md">
          <Outlet />
        </Container>
      </Center>
    </Box>
  </Flex>
)

export default SessionShell
