import { Group, IconButton, Separator } from '@chakra-ui/react'
import type { ConditionalValue } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo, useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ToggleAiBuilderModeButton from './toggleAiBuilderModeButton'

import { FileUploadRoot, FileUploadTrigger } from '@app/components/ui/file-button'
import { Tooltip } from '@app/components/ui/tooltip'
import useGetObjects from '@app/hooks/useGetObjects'
import useMapHotkeys from '@app/hooks/useMapHotkeys'
import useMapNodeCreators from '@app/hooks/useMapNodeCreators'
import useMousePosition from '@app/hooks/useMousePosition'
import BasicTypeModal from '@app/pages/maps/components/nodes/components/basicType/basicTypeModal'
import { iconMap as basicCardIconMap } from '@app/pages/maps/components/nodes/components/basicType/customIcons'
import Can from '@app/shared/authorization/can'
import KeyboardShortcutsTooltip from '@app/shared/keyboardShortcutsTooltip'
import ICON_MAP from '@app/utils/iconMap'

const buttonStyles = {
  fontSize: 'xl',
  borderRadius: 0,
  gap: 0,
  size: 'md' as ConditionalValue<'sm' | 'md' | 'lg' | 'xl' | '2xl' | '2xs' | 'xs'>,
  variant: 'ghost' as ConditionalValue<'outline' | 'ghost' | 'solid' | 'subtle' | 'surface' | 'plain'>,
  colorPalette: 'gray'
}

const tooltipPlacement = 'right'

const sectionLabel = <KeyboardShortcutsTooltip title="Section" shortcut="S" />
const noteLabel = <KeyboardShortcutsTooltip title="Note" shortcut="N" />
const imageLabel = <KeyboardShortcutsTooltip title="Image" shortcut="I" />
const commentLabel = <KeyboardShortcutsTooltip title="Comment" shortcut="T" />

type Props = {
  strategyId: string
}

const AddCardMenu: FC<Props> = ({ strategyId }) => {
  const navigate = useNavigate()

  const imageInputRef = useRef<HTMLInputElement>(null)
  const { createCommentThread, createNote, createSection, createImage } = useMapNodeCreators(strategyId)
  useMapHotkeys(strategyId, imageInputRef)
  const getLatestMousePosition = useMousePosition()

  const cardTypes = useGetObjects('cardType')
  const sortedCardTypes = useMemo(() => cardTypes.sort((a, b) => a.name.localeCompare(b.name)), [cardTypes])
  const shownCardTypes = useMemo(() => sortedCardTypes.filter((ct) => ct.showInMenu), [sortedCardTypes])
  const [open, setOpen] = useState(false)

  document.onpaste = useCallback(
    (evt) => {
      const dataTransferred = evt.clipboardData
      const mousePosition = getLatestMousePosition()

      if (dataTransferred.files.length > 0) {
        createImage(dataTransferred.files[0], mousePosition)
      }
    },
    [createImage, getLatestMousePosition]
  )

  const openExistingObjectDrawer = useCallback(
    (type) => {
      navigate(`add/${type}`)
    },
    [navigate]
  )

  return (
    <>
      <Group gap={0} orientation="vertical">
        <Tooltip
          content={<KeyboardShortcutsTooltip title="Metric" shortcut="M" />}
          positioning={{ placement: tooltipPlacement }}
          showArrow
        >
          <IconButton
            {...buttonStyles}
            aria-label="metric"
            data-toolbar-button-id="metric"
            onClick={() => openExistingObjectDrawer('metric')}
          >
            <ICON_MAP.Metric />
          </IconButton>
        </Tooltip>

        <Tooltip
          content={<KeyboardShortcutsTooltip title="Work" shortcut="W" />}
          positioning={{ placement: tooltipPlacement }}
          showArrow
        >
          <IconButton
            {...buttonStyles}
            aria-label="work"
            data-toolbar-button-id="work"
            onClick={() => openExistingObjectDrawer('entity')}
          >
            <ICON_MAP.Work />
          </IconButton>
        </Tooltip>
        <Tooltip content="Submap" positioning={{ placement: tooltipPlacement }} showArrow>
          <IconButton
            {...buttonStyles}
            aria-label="map"
            data-toolbar-button-id="map"
            onClick={() => openExistingObjectDrawer('strategy')}
          >
            <ICON_MAP.StrategyMap />
          </IconButton>
        </Tooltip>
        <Can I="view" a="admin">
          <ToggleAiBuilderModeButton {...buttonStyles} />
        </Can>
        {shownCardTypes.length > 0 && <Separator />}
        {shownCardTypes?.map((type) => {
          const IconComponent = basicCardIconMap[type.icon] || ICON_MAP.BasicCard

          return (
            <Tooltip key={type.id} content={type.name} positioning={{ placement: tooltipPlacement }} showArrow>
              <IconButton
                {...buttonStyles}
                aria-label={type.name}
                data-toolbar-button-id={`card-${type.name}`}
                onClick={() => openExistingObjectDrawer(`basicCard/${type.id}`)}
              >
                <IconComponent />
              </IconButton>
            </Tooltip>
          )
        })}

        <Separator />

        <Tooltip content={sectionLabel} positioning={{ placement: tooltipPlacement }} showArrow>
          <IconButton {...buttonStyles} data-toolbar-button-id="section" aria-label="section" onClick={createSection}>
            <ICON_MAP.Section />
          </IconButton>
        </Tooltip>

        <Tooltip content={noteLabel} positioning={{ placement: tooltipPlacement }} showArrow>
          <IconButton {...buttonStyles} data-toolbar-button-id="note" aria-label="note" onClick={createNote}>
            <ICON_MAP.Note />
          </IconButton>
        </Tooltip>

        <Tooltip content={imageLabel} positioning={{ placement: tooltipPlacement }} showArrow>
          <FileUploadRoot accept="image/*" onFileAccept={(e) => createImage(e.files[0])}>
            <FileUploadTrigger asChild>
              <IconButton {...buttonStyles} aria-label="image">
                <ICON_MAP.Image />
              </IconButton>
            </FileUploadTrigger>
          </FileUploadRoot>
        </Tooltip>

        <Tooltip content={commentLabel} positioning={{ placement: tooltipPlacement }} showArrow>
          <IconButton
            {...buttonStyles}
            data-toolbar-button-id="comment"
            aria-label="comment"
            onClick={createCommentThread}
          >
            <ICON_MAP.Comments />
          </IconButton>
        </Tooltip>
        <Tooltip content="Playbook" positioning={{ placement: tooltipPlacement }} showArrow>
          <IconButton
            {...buttonStyles}
            aria-label="playbook"
            data-toolbar-button-id="playbook"
            onClick={() => openExistingObjectDrawer('playbook')}
          >
            <ICON_MAP.Playbook />
          </IconButton>
        </Tooltip>
        <Separator />

        <Tooltip content="Manage card types" positioning={{ placement: tooltipPlacement }} showArrow>
          <IconButton
            {...buttonStyles}
            data-toolbar-button-id="manage-cards"
            aria-label="manage card types"
            onClick={() => setOpen(true)}
          >
            <ICON_MAP.Ellipsis />
          </IconButton>
        </Tooltip>
      </Group>
      <BasicTypeModal
        cardTypes={cardTypes}
        open={open}
        onOpenChange={(e) => {
          setOpen(e.open)
        }}
      />
    </>
  )
}

AddCardMenu.displayName = 'AddCardMenu'

export default memo(AddCardMenu)
