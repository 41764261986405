import { Link } from '@chakra-ui/react'
import type { FC } from 'react'

import { ClipboardIconButton, ClipboardInput, ClipboardRoot } from '@app/components/ui/clipboard'
import { Field } from '@app/components/ui/field'
import { InputGroup } from '@app/components/ui/input-group'
import type { Strategy } from '@graphql/queries'
import { SharingTypesEnum } from '@graphql/queries'

interface Props {
  strategy: Pick<Strategy, 'id' | 'token'>
  sharing: SharingTypesEnum
}

const StrategyFormUrl: FC<Props> = ({ strategy = null, sharing = SharingTypesEnum.PrivateAccess }) => {
  let url = ''

  if (strategy?.id) {
    const strategyToken = sharing === SharingTypesEnum.TokenAccess ? strategy.token : null
    const shareUrl = `${window.location.origin}/strategy/${strategy.id}/map`

    url = strategyToken ? `${shareUrl}?token=${strategyToken}` : shareUrl
  }

  if (!strategy) {
    return null
  }

  return (
    <Field
      label="Share URL"
      helperText={
        <Link colorPalette="blue" wordBreak="break-all" href={url} rel="onoopener noreferrer" target="_blank">
          Open in new tab
        </Link>
      }
      w="full"
    >
      <ClipboardRoot value={url} w="full">
        <InputGroup width="full" endElement={<ClipboardIconButton me="-2" />}>
          <ClipboardInput readOnly value={url} />
        </InputGroup>
      </ClipboardRoot>
    </Field>
  )
}

export default StrategyFormUrl
