import { Box, Heading, HStack, Link, Spacer, Stack } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import debounce from 'lodash/debounce'
import set from 'lodash/set'
import type { ChangeEventHandler, FC } from 'react'
import { useState, Suspense, memo, useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useParams } from 'react-router-dom'
import type { AnyVariables } from 'urql'

import ExistingCardsList from './existingCardsList'
import QueryExistingCardForm from './queryExisting/queryExistingCardForm'

import { Button } from '@app/components/ui/button'
import useCreateNewObject from '@app/hooks/useCreateNewObject'
import { deriveType, showAddButton } from '@app/pages/maps/components/addCards/helpers'
import MapDrawer from '@app/pages/maps/components/drawer/mapDrawer'
import { useDrawerContext } from '@app/pages/maps/drawerContext'
import { useStrategyMapContext } from '@app/pages/maps/strategyMapContext'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'
import SuspenseLoader from '@app/shared/loaders/suspenseLoader'
import type { Nodes } from '@app/types'
import { loaderQuery } from '@graphql/client'
import { StrategyExistingObjectAutocomplete } from '@graphql/documents/strategy.graphql'
import type {
  StrategyExistingObjectAutocompleteQuery,
  StrategyExistingObjectAutocompleteQueryVariables
} from '@graphql/queries'

type Props = {
  cardType?: Nodes
  headerText?: string
  buttonText?: string
  mutationVariables?: AnyVariables
}

const AddCardDrawer: FC<Props> = ({ cardType = null, headerText = null, buttonText = null, mutationVariables }) => {
  const { strategyId, type: paramType, cardTypeId } = useParams()
  const { onDrawerClose } = useDrawerContext()

  const { canEdit } = useStrategyMapContext()
  const type = cardType || (paramType as Nodes)
  let transformedVariables = mutationVariables

  if (paramType === 'entity') {
    transformedVariables = { ...transformedVariables, sourceName: 'web' }
  }

  const [searchResults, setSearchResults] = useState(null)
  const [query, setQuery] = useState('')

  const displayType = type === 'entity' ? 'Work' : capitalize(type)
  const displayAddButton = showAddButton(type)
  useHotkeys('esc', () => onDrawerClose())

  const createNewObject = useCreateNewObject({ mutationVariables: transformedVariables })

  const handleAddNew = () => {
    createNewObject(strategyId, type)
    onDrawerClose()
  }

  // If they land here on refresh, close the drawer and redirect to the map
  useEffect(() => {
    if (!canEdit) {
      onDrawerClose()
    }
  }, [canEdit, onDrawerClose])

  const onChange = debounce<ChangeEventHandler<HTMLFormElement>>(async (e) => {
    e.preventDefault()

    const formData = new FormData(e.target.form)
    const formObject = Object.fromEntries(formData.entries())

    const variables = {
      id: strategyId,
      type: deriveType(type),
      query: '',
      scope: {}
    }

    Object.entries(formObject).forEach(([key, value]) => {
      set(variables, key, value)
    })

    setQuery(variables.query)

    const { data } = await loaderQuery<
      StrategyExistingObjectAutocompleteQuery,
      StrategyExistingObjectAutocompleteQueryVariables
    >(StrategyExistingObjectAutocomplete, variables)

    setSearchResults(data?.strategy?.existingObjectAutocomplete || [])
  }, 100)

  if (!canEdit) {
    return null
  }

  let subHeader = null
  if (type === 'metric') {
    subHeader = (
      <Link
        color="link"
        fontSize="sm"
        href={`/${type}s`}
        rel="onoopener noreferrer"
        target="_blank"
      >{`Manage ${type}s library`}</Link>
    )
  }

  return (
    <MapDrawer data-testid="add-existing-drawer">
      <Stack as="section" gap={4} px={4}>
        <HStack>
          <Stack>
            <Heading size="md">{headerText || `${displayType} Cards`}</Heading>
            {subHeader}
          </Stack>
          <Spacer />
          <DrawerCloseButton variant="ghost" />
        </HStack>
        {displayAddButton && (
          <Box w="100%" py={2}>
            <Button w="100%" data-testid="button-add-existing" onClick={handleAddNew}>
              + {buttonText || `New ${displayType}`}
            </Button>
          </Box>
        )}
      </Stack>
      <Stack as="section" gap={0} px={4}>
        <Suspense fallback={<SuspenseLoader />}>
          <QueryExistingCardForm onChange={onChange} type={type} cardTypeId={cardTypeId} />
        </Suspense>
      </Stack>
      <Stack as="section" gap={0} overflow="auto" px={4}>
        <ExistingCardsList strategyId={strategyId} objects={searchResults} query={query} />
      </Stack>
    </MapDrawer>
  )
}

export default memo(AddCardDrawer)
