const bookmarkletJS = () => {
  const amplitudeChartMatcher = /https:\/\/app\.amplitude\.com\/analytics\/[^/]+\/chart\/[^/]+/
  const links = document.links
  const bulkHosts = ['app.amplitude.com']
  const host = window.location.host
  let importable = true

  const styledEl = (element, styles = {}) => {
    const el = document.createElement(element)

    Object.entries(styles).forEach(([prop, value]) => {
      el.style[prop] = value
    })

    return el
  }

  const dialog = styledEl('dialog', {
    display: 'flex',
    border: '1px solid #ccc',
    width: '550px',
    borderRadius: '5px',
    boxShadow: '2px 2px 10px 0px #8a8e99',
    flexDirection: 'column',
    fontSize: '14px'
  })

  const headerDiv = styledEl('div', {
    padding: '4px',
    fontWeight: '600',
    fontSize: '18px'
  })

  const bodyDiv = styledEl('div', {
    display: 'flex',
    flexGrow: '1',
    padding: '4px',
    minHeight: '400px'
  })

  const textarea = styledEl('textarea', {
    padding: '4px',
    margin: '4px 0 4px',
    width: '100%',
    lineHeight: 1.25,
    overflowY: 'auto',
    resize: 'none'
  })

  const footerDiv = styledEl('div', {
    display: 'flex',
    flexGrow: '0'
  })

  const dialogButtonsDiv = styledEl('div', {
    padding: '4px',
    width: '100%',
    display: 'flex',
    gap: '8px',
    justifyContent: 'end'
  })

  const dialogCloseButton = styledEl('button', {
    cursor: 'pointer',
    lineHeight: '1.25',
    borderRadius: '4px',
    backgroundColor: 'white',
    color: 'black',
    userSelect: 'none',
    appearance: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    borderWidth: '1px',
    outline: '0',
    height: '32px',
    paddingInline: '16px'
  })

  const copyToClipboardButton = styledEl('button', {
    cursor: 'pointer',
    lineHeight: '1.25',
    borderRadius: '4px',
    backgroundColor: 'black',
    color: 'white',
    userSelect: 'none',
    appearance: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    borderWidth: '1px',
    outline: '0',
    height: '32px',
    paddingInline: '16px',
    whiteSpace: 'nowrap'
  })

  const handleCloseClick = () => {
    dialog.close()
  }

  const handleDialogClose = () => {
    document.body.removeChild(dialog)
  }

  const handleCopyToClipboard = async () => {
    await navigator.clipboard.writeText(textarea.value)
    copyToClipboardButton.textContent = 'Copied!'

    setTimeout(() => {
      copyToClipboardButton.textContent = 'Copy to Clipboard'
    }, 2000)
  }

  headerDiv.textContent = 'DoubleLoop Bulk Import'
  textarea.disabled = true

  dialog.appendChild(headerDiv)
  dialog.appendChild(bodyDiv)
  dialog.appendChild(footerDiv)

  dialog.id = 'dl-bulk'
  dialog.addEventListener('close', handleDialogClose)

  dialogCloseButton.textContent = 'Close'
  dialogCloseButton.onclick = handleCloseClick

  copyToClipboardButton.textContent = 'Copy to Clipboard'
  copyToClipboardButton.onclick = handleCopyToClipboard

  dialogButtonsDiv.appendChild(dialogCloseButton)
  dialogButtonsDiv.appendChild(copyToClipboardButton)

  bodyDiv.appendChild(textarea)
  footerDiv.appendChild(dialogButtonsDiv)

  if (!bulkHosts.includes(host)) {
    importable = false
    alert('DoubleLoop bulk import is not available on this page.')
  } else if (!document.getElementById('dl-bulk')) {
    document.body.appendChild(dialog)
  }

  if (host === 'app.amplitude.com') {
    const charts = Array.from(links).filter((link) => amplitudeChartMatcher.test(link.href))
    const chartUrls = charts.map((chart) => {
      const chartUrl = chart.href.split('?')[0]
      const chartTitle = chart.innerText

      if (chartTitle) {
        return `${chartUrl}?title=${encodeURI(chartTitle)}`
      }

      return chartUrl
    })
    const chartUrlsString = chartUrls.join('\n')

    textarea.innerHTML = chartUrlsString
  }

  if (importable) {
    try {
      dialog.showModal()
    } catch (_e) {
      return
    }
  }
}

export default bookmarkletJS
