import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { PiGear } from 'react-icons/pi'
import { Link } from 'react-router-dom'

import { Tooltip } from '@app/components/ui/tooltip'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import StrategySlackDisplay from '@app/pages/maps/components/strategySlackDisplay'
import { useStrategyMapContext } from '@app/pages/maps/strategyMapContext'
import Can from '@app/shared/authorization/can'
import type { Strategy } from '@graphql/types'

interface Props {
  strategy: Strategy
  show?: boolean
}

const Settings: FC<Props> = ({ strategy, show = true }) => {
  const { user } = useStoreCurrentUser()
  const { canEdit } = useStrategyMapContext()

  if (!canEdit || !user || !show) {
    return null
  }

  return (
    <Can I="update" a="strategy">
      <Tooltip content="Map settings" positioning={{ placement: 'left' }} showArrow>
        <IconButton fontSize="lg" aria-label="Map settings" asChild colorPalette="gray" variant="ghost">
          <Link to="map/settings">
            <PiGear />
          </Link>
        </IconButton>
      </Tooltip>
      <StrategySlackDisplay strategy={strategy} aria-label="Integrate Slack" size="xs" variant="ghost" />
    </Can>
  )
}

export default Settings
