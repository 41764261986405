import { Icon, IconButton, PopoverContext } from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'
import { LuAlignRight, LuX } from 'react-icons/lu'

import { PopoverContent, PopoverRoot, PopoverTrigger } from '@app/components/ui/popover'

const NavMobilePopover = (props: PropsWithChildren) => (
  <PopoverRoot
    positioning={{
      placement: 'bottom',
      overflowPadding: 0,
      offset: { mainAxis: 12 }
    }}
  >
    <PopoverContext>
      {(context) => (
        <PopoverTrigger asChild>
          <IconButton aria-label="Open Menu" variant="ghost" size="sm" colorPalette="gray" hideFrom="md">
            <Icon size="md">{context.open ? <LuX /> : <LuAlignRight />}</Icon>
          </IconButton>
        </PopoverTrigger>
      )}
    </PopoverContext>
    <PopoverContent
      textStyle="md"
      boxShadow="none"
      borderRadius="none"
      maxW="unset"
      px="4"
      py="6"
      width="var(--available-width)"
      height="var(--available-height)"
      {...props}
    />
  </PopoverRoot>
)

export default NavMobilePopover
