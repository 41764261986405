import { Box, IconButton, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { Tooltip } from '@app/components/ui/tooltip'
import GoalDeleteButton from '@app/pages/metrics/components/goals/goalDeleteButton'
import Can from '@app/shared/authorization/can'
import type { Goal } from '@graphql/queries'

type Props = {
  goal: Pick<Goal, 'id'>
}

const GoalActions: FC<Props> = ({ goal }) => (
  <Stack data-testid="goal-actions">
    <Can I="update" a="goal">
      <Box>
        <Tooltip content="Edit key result" showArrow positioning={{ placement: 'left' }}>
          <IconButton aria-label="Edit key result" size="sm" asChild variant="subtle">
            <Link to={`${goal.id}/edit`}>
              <FiEdit2 />
            </Link>
          </IconButton>
        </Tooltip>
      </Box>
    </Can>
    <Can I="delete" a="goal">
      <GoalDeleteButton goalId={goal.id}>
        <Tooltip content="Delete key result" showArrow positioning={{ placement: 'left' }}>
          <IconButton aria-label="Delete key result" size="sm" variant="subtle">
            <FiTrash2 />
          </IconButton>
        </Tooltip>
      </GoalDeleteButton>
    </Can>
  </Stack>
)

export default GoalActions
