import { IconButton, Spinner } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { useState } from 'react'

import type { ButtonProps } from '@app/components/ui/button'
import { toaster } from '@app/components/ui/toaster'
import type { DirectionType } from '@app/pages/maps/components/nodes/components/ai/aiNodeMenu'
import { useStore } from '@app/store'
import type { DomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import { AiNodesGenerate } from '@graphql/documents/ai_assistant.graphql'
import type { AiNodesGenerateMutation, AiNodesGenerateMutationVariables } from '@graphql/queries'

interface Props {
  node: Pick<DomainNode, 'nodeId'>
  aiAssistantSlug: string
  direction: DirectionType
  handle: 'top' | 'bottom' | 'left' | 'right'
  children?: (props: Partial<ButtonProps>) => ReactNode
}

const buttonStyles = {
  borderRadius: 'sm'
}

const defaultChildren = ({ loading, onClick }) => (
  <IconButton {...buttonStyles} aria-label="Suggest downstream metrics" onClick={onClick} size="sm" variant="outline">
    {loading ? <Spinner size="xs" /> : <ICON_MAP.AiPrompt />}
  </IconButton>
)

const SuggestConnectedMetricsButton: FC<Props> = ({
  node,
  aiAssistantSlug,
  direction,
  handle,
  children = defaultChildren
}) => {
  const [loading, setLoading] = useState(false)
  const actionMutation = useStore.use.actionMutation()

  const onClick = async () => {
    setLoading(true)

    try {
      const result = await actionMutation<AiNodesGenerateMutation, AiNodesGenerateMutationVariables>(AiNodesGenerate, {
        nodeId: node.nodeId,
        aiAssistantSlug: aiAssistantSlug,
        direction: direction,
        handle: handle
      })

      if (result.error) {
        if (result.error.networkError) {
          toaster.create({ title: 'Error', description: result.error.networkError.message, type: 'error' })
        } else {
          const errors = result.error.graphQLErrors.map((e) => e.message).join(', ')
          toaster.create({ title: 'Error', description: errors, type: 'error' })
        }
      } else {
        toaster.create({ title: 'Success', description: 'Connected metrics suggested successfully', type: 'success' })
      }
    } catch (e) {
      toaster.create({ title: 'Error', description: e.message, type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return children({ loading, onClick })
}

export default SuggestConnectedMetricsButton
