import { Group, HStack, Icon, IconButton, Input, Spinner, Text } from '@chakra-ui/react'
import type { ChangeEventHandler, KeyboardEvent } from 'react'
import { useState } from 'react'
import { FiCheck, FiChevronDown, FiX } from 'react-icons/fi'
import { useFetcher } from 'react-router-dom'

import ColorPickerPopover from './colorPickerPopover'
import ColorSquare from './colorSquare'
import IconPickerPopover from './iconPickerPopover'

import { Button } from '@app/components/ui/button'
import { Checkbox } from '@app/components/ui/checkbox'
import { iconMap } from '@app/pages/maps/components/nodes/components/basicType/customIcons'
import { useStore } from '@app/store'
import { CardTypeCreate } from '@graphql/documents/card_types.graphql'
import type { CardTypeCreateMutation, CardTypeCreateMutationVariables } from '@graphql/queries'

const CreateBasicCardTypeButton = () => {
  const [editing, setEditing] = useState(false)
  const [showInMenu, setShowInMenu] = useState(true)
  const [name, setName] = useState('')
  const [color, setColor] = useState('')
  const [icon, setIcon] = useState('')
  const [loading, setLoading] = useState(false)

  const typeCreateFetcher = useFetcher()

  const IconComponent = iconMap[icon]
  const iconColor = color ? `${color}.500` : 'gray.500'
  const renderedIcon = icon ? (
    <Icon boxSize={5} color={iconColor}>
      <IconComponent />
    </Icon>
  ) : (
    <Text>None</Text>
  )
  const actionMutation = useStore.use.actionMutation()

  const onSubmit = async () => {
    setLoading(true)
    try {
      await actionMutation<CardTypeCreateMutation, CardTypeCreateMutationVariables>(CardTypeCreate, {
        name,
        color,
        icon,
        showInMenu
      })
      setEditing(false)
      setName('')
      setColor('')
      setIcon('')
      setShowInMenu(true)
    } finally {
      setLoading(false)
    }
  }

  const handleShowInMenuChange = (e: boolean) => {
    setShowInMenu(e)
  }

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value)
  }

  const handleColorChange = (e: string) => {
    setColor(e)
  }

  const handleIconChange = (e: string) => {
    setIcon(e)
  }

  const handleSubmitOnEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  if (editing) {
    return (
      <HStack>
        <Checkbox checked={showInMenu} onCheckedChange={(e) => handleShowInMenuChange(!!e.checked)} />
        <ColorPickerPopover onClick={handleColorChange} value={color}>
          <HStack w="50px" cursor="pointer">
            <ColorSquare color={color} />
            <Icon>
              <FiChevronDown />
            </Icon>
          </HStack>
        </ColorPickerPopover>
        <IconPickerPopover onClick={handleIconChange} value={icon} color={color}>
          <HStack cursor="pointer">
            {renderedIcon}
            <Icon>
              <FiChevronDown />
            </Icon>
          </HStack>
        </IconPickerPopover>
        <Input
          onKeyUp={handleSubmitOnEnter}
          defaultValue={name}
          name="name"
          onChange={handleNameChange}
          placeholder="Name..."
          disabled={loading}
          required
        />
        <Group attached>
          <IconButton aria-label="Create card type" colorPalette="green" disabled={!name} onClick={onSubmit}>
            {loading ? <Spinner size="xs" /> : <FiCheck />}
          </IconButton>
          <IconButton aria-label="Cancel" colorPalette="red" onClick={() => setEditing(false)}>
            <FiX />
          </IconButton>
        </Group>
      </HStack>
    )
  }

  return (
    <Button w="100%" loading={typeCreateFetcher.state !== 'idle'} onClick={() => setEditing(true)} variant="subtle">
      Create type
    </Button>
  )
}

export default CreateBasicCardTypeButton
