import { Box, Flex, HStack, Stack, Text } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import GoalActions from '@app/pages/metrics/components/goals/goalActions'
import Table from '@app/shared/table'
import type { Column } from '@app/shared/table/types'
import type { Goal } from '@graphql/types'

type Props = {
  goals: Goal[]
}

const columns: Column<Goal>[] = [
  {
    header: 'Configuration',
    cell: ({ data }) => (
      <Stack>
        <HStack>
          <Text w="48px" fontWeight="bold">
            Range:
          </Text>
          <Text>{data.label}</Text>
        </HStack>
        <HStack>
          <Text w="48px" fontWeight="bold">
            Start:
          </Text>
          <Text>{Intl.NumberFormat().format(data.startValue)}</Text>
        </HStack>
        <HStack>
          <Text w="48px" fontWeight="bold">
            Target:
          </Text>
          <Text>{Intl.NumberFormat().format(data.targetValue)}</Text>
        </HStack>
      </Stack>
    )
  },
  {
    header: 'Values',
    cell: ({ data }) => (
      <Stack>
        <HStack>
          <Text w="48px" fontWeight="bold">
            Status:
          </Text>
          <Text>{capitalize(data.status)}</Text>
        </HStack>
        <HStack>
          <Text w="48px" fontWeight="bold">
            Actual:
          </Text>
          <Text>{Intl.NumberFormat().format(data.currentValue)}</Text>
        </HStack>
        <HStack>
          <Text w="48px" fontWeight="bold">
            Done:
          </Text>
          <Text>{Intl.NumberFormat(undefined, { style: 'percent' }).format(data.percentageComplete / 100)}</Text>
        </HStack>
      </Stack>
    )
  },
  {
    header: '',
    cell: ({ data }) => (
      <Box textAlign="end">
        <GoalActions goal={data} />
      </Box>
    )
  }
]

const GoalsTable: FC<Props> = ({ goals }) => (
  <Stack data-testid="goals-table">
    <Table columns={columns} data={goals} rowFormatter={() => ({ verticalAlign: 'top' })} />
    <Flex justify="flex-end" mx={5}>
      <Button asChild size="xs">
        <Link to="new">Add key result</Link>
      </Button>
    </Flex>
  </Stack>
)

export default GoalsTable
