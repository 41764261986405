import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Tooltip } from '@app/components/ui/tooltip'
import { useStrategyMapContext } from '@app/pages/maps/strategyMapContext'
import ICON_MAP from '@app/utils/iconMap'

const buttonStyles = {
  borderRadius: 'sm'
}

interface Props {
  path: string
}

const SourceButton: FC<Props> = ({ path }) => {
  const navigate = useNavigate()

  const { canEdit } = useStrategyMapContext()

  if (!canEdit) {
    return null
  }

  const openDrawer = () => {
    navigate(path)
  }

  return (
    <Tooltip content="Data source">
      <IconButton {...buttonStyles} aria-label="data source" onClick={openDrawer} size="sm" variant="outline">
        <ICON_MAP.DataSource />
      </IconButton>
    </Tooltip>
  )
}

export default SourceButton
