import { Button, HStack, Separator, Spacer, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useId } from 'react'
import { PiShareFatFill } from 'react-icons/pi'

import {
  DialogBody,
  DialogBackdrop,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
  DialogActionTrigger
} from '@app/components/ui/dialog'
import { Tooltip } from '@app/components/ui/tooltip'
import CopyEmbedCodeButton from '@app/pages/maps/components/sharing/copyEmbedCodeButton'
import StrategyFormAccess from '@app/pages/maps/components/sharing/strategyFormAccess'
import type { DomainStrategy } from '@app/types'
import { toSentenceCase } from '@app/utils/stringHelpers'

interface Props {
  strategy: DomainStrategy
  show?: boolean
}

const SharingDialog: FC<Props> = ({ strategy, show = true }) => {
  const triggerId = useId()

  if (!show) {
    return null
  }

  return (
    <DialogRoot ids={{ trigger: triggerId }}>
      <DialogTrigger>
        <Tooltip
          content={toSentenceCase(strategy.sharing)}
          positioning={{ placement: 'left' }}
          showArrow
          ids={{ trigger: triggerId }}
        >
          <Button colorPalette="green" size="xs" data-button-id="map-share">
            <PiShareFatFill />
            Share
          </Button>
        </Tooltip>
      </DialogTrigger>
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger />
        <DialogHeader>
          <DialogTitle>Sharing & Privacy</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Stack gap={4} separator={<Separator />}>
            <StrategyFormAccess strategy={strategy} />
            <HStack>
              <Text fontSize="sm" fontWeight="medium">
                Embed code
              </Text>
              <Spacer />
              <CopyEmbedCodeButton strategy={strategy} />
            </HStack>
          </Stack>
        </DialogBody>
        <DialogFooter>
          <DialogActionTrigger>
            <Button>Close</Button>
          </DialogActionTrigger>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  )
}

export default SharingDialog
