import { Box, Input, Link, Separator, Stack, Table, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import useGetObject from '@app/hooks/useGetObject'
import ConfidenceRatingPopover from '@app/next/confidenceRatingPopover'
import LabelsInput from '@app/next/forms/inline/labelsInput'
import RichTextInput from '@app/next/forms/inline/richTextInput'
import RichTextInputWithLabel from '@app/next/forms/inline/richTextInputWithLabel'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import InlineUpdaterRoot from '@app/next/forms/inlineUpdaterRoot'
import UrlInput from '@app/next/forms/urlInput'
import OwnerPopover from '@app/next/ownerPopover'
import MetricGraph from '@app/pages/metrics/components/metricGraph'
import DetailsTable from '@app/pages/shared/detailsTable'
import Can from '@app/shared/authorization/can'
import CardStats from '@app/shared/cards/metric/cardStats'

const MetricDetails: FC = () => {
  const { nodeId, strategyId } = useParams()
  const metric = useGetObject(nodeId, 'metric')
  const { externalUrl, externalUrlTitle, slug, aiGenerated } = metric

  return (
    <Stack gap={4}>
      <MetricGraph metric={metric} />
      <Separator />
      <Box px={4}>{strategyId && <CardStats strategyId={strategyId} metric={metric} forceStats={true} />}</Box>
      <Separator />
      <Stack gap={4} p={4}>
        <ViewerFieldWrapper value={metric.description}>
          <RichTextInput domainObject={metric} name="description" placeholder="Description..." />
        </ViewerFieldWrapper>
        <ViewerFieldWrapper value={metric.technicalDescription}>
          <RichTextInputWithLabel
            domainObject={metric}
            name="technicalDescription"
            placeholder="Technical description..."
            label="Technical description"
          />
        </ViewerFieldWrapper>

        <DetailsTable>
          <Table.Body>
            <Table.Row>
              <Table.Cell fontWeight="semibold">
                <Text>ID</Text>
              </Table.Cell>
              <Table.Cell>
                <Text>{slug}</Text>
              </Table.Cell>
            </Table.Row>
            <ViewerFieldWrapper value={!!externalUrl}>
              <Table.Row>
                <Table.Cell fontWeight="semibold">
                  {externalUrl ? (
                    <Link
                      color="link"
                      fontSize="sm"
                      whiteSpace="no"
                      wordBreak="break-all"
                      href={externalUrl}
                      rel="onoopener noreferrer"
                      target="_blank"
                    >
                      External URL
                    </Link>
                  ) : (
                    <Text>External URL</Text>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <InlineUpdaterRoot domainObject={metric}>
                    <UrlInput defaultValue={externalUrl || ''} name="externalUrl" placeholder="External URL..." />
                  </InlineUpdaterRoot>
                </Table.Cell>
              </Table.Row>
            </ViewerFieldWrapper>
            <ViewerFieldWrapper value={metric.externalUrlTitle}>
              <Table.Row>
                <Table.Cell fontWeight="semibold">External URL title</Table.Cell>
                <Table.Cell>
                  <InlineUpdaterRoot domainObject={metric}>
                    <Input defaultValue={externalUrlTitle || ''} name="externalUrlTitle" placeholder="See more..." />
                  </InlineUpdaterRoot>
                </Table.Cell>
              </Table.Row>
            </ViewerFieldWrapper>
            <ViewerFieldWrapper value={metric.owner}>
              <Table.Row>
                <Table.Cell fontWeight="semibold">Owner</Table.Cell>
                <Table.Cell>
                  <OwnerPopover object={metric} showName />
                </Table.Cell>
              </Table.Row>
            </ViewerFieldWrapper>
            <ViewerFieldWrapper value={metric.labels}>
              <Table.Row>
                <Table.Cell fontWeight="semibold">Labels</Table.Cell>
                <Table.Cell>
                  <LabelsInput domainObject={metric} name="labels" />
                </Table.Cell>
              </Table.Row>
            </ViewerFieldWrapper>
            <ViewerFieldWrapper value={typeof metric.confidenceRating === 'number'}>
              <Table.Row>
                <Table.Cell fontWeight="semibold">Confidence</Table.Cell>
                <Table.Cell>
                  <ConfidenceRatingPopover domainObject={metric} />
                </Table.Cell>
              </Table.Row>
            </ViewerFieldWrapper>
            <Can I="view" an="admin">
              <Table.Row>
                <Table.Cell fontWeight="semibold">AI Generated</Table.Cell>
                <Table.Cell>
                  <NativeSelectRoot>
                    <InlineUpdaterRoot domainObject={metric}>
                      <NativeSelectField name="aiGenerated" defaultValue={aiGenerated?.toString()} placeholder={null}>
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </NativeSelectField>
                    </InlineUpdaterRoot>
                  </NativeSelectRoot>
                </Table.Cell>
              </Table.Row>
            </Can>
          </Table.Body>
        </DetailsTable>
      </Stack>
    </Stack>
  )
}

export default MetricDetails
