import type { RouteObject } from 'react-router-dom'
import { Outlet } from 'react-router-dom'

import ChatDrawer from '@app/pages/maps/components/aiChat/components/chatDrawer'
import List from '@app/pages/maps/components/aiChat/list'
import New from '@app/pages/maps/components/aiChat/new'
import Show from '@app/pages/maps/components/aiChat/show'
import { useStore } from '@app/store'
import { Chat, StrategyChats } from '@graphql/documents/ai_assistant.graphql'
import { PipelineEnum } from '@graphql/types'

const chatBotRoutes = (root: string, pipelineId: PipelineEnum) => ({
  path: root,
  element: <ChatDrawer />,
  children: [
    {
      index: true,
      element: <List pipelineId={pipelineId} />,
      loader: async ({ params }) => {
        const { strategyId } = params
        const { loaderQuery } = useStore.getState()

        return loaderQuery(StrategyChats, { id: strategyId })
      }
    },
    {
      path: 'new',
      element: <New pipelineId={pipelineId} />
    },
    {
      path: ':chatId',
      element: <Show pipelineId={pipelineId} />,
      loader: async ({ params }) => {
        const { chatId } = params
        const { loaderQuery } = useStore.getState()

        return loaderQuery(Chat, { id: chatId })
      }
    }
  ]
})

const routes: RouteObject = {
  path: 'ai',
  element: <Outlet />,
  children: [
    chatBotRoutes('chat', PipelineEnum.DataScientistChat),
    chatBotRoutes('strategy_context', PipelineEnum.StrategyContext),
    chatBotRoutes('kpi_tree_generator', PipelineEnum.KpiTreeGenerator),
    chatBotRoutes('kpi_tree_generator_dev', PipelineEnum.KpiTreeGeneratorDev),

    chatBotRoutes('map_generator', PipelineEnum.MapGenerator),
    chatBotRoutes('map_generator_northstar_playbook', PipelineEnum.MapGeneratorNorthstarPlaybook)
  ]
}

export default routes
