import { Box, Flex, useToken } from '@chakra-ui/react'
import type { FC } from 'react'
import { cloneElement } from 'react'

import { Tooltip } from '@app/components/ui/tooltip'
import { totalEntities } from '@app/shared/cards/entity/helpers'
import type { CardSize } from '@app/types'
import type { EntityWorkflowStateEnum } from '@graphql/types'

const colorPalettes = {
  not_started: 'yellow',
  started: 'blue',
  reviewing: 'teal',
  finished: 'green'
}

const statuscolorPalette = (workflowState: EntityWorkflowStateEnum) => colorPalettes[workflowState] || 'yellow'

interface Props {
  progresses: Record<EntityWorkflowStateEnum, number>
  workflowState?: EntityWorkflowStateEnum | null
  size?: CardSize
}

const CardMultiStepProgress: FC<Props> = ({ progresses = {}, workflowState = null, size = 'normal' }) => {
  const colorPalette = statuscolorPalette(workflowState)
  const colors = useToken('colors', [
    `${colorPalette}.600`,
    `${colorPalette}.500`,
    `${colorPalette}.400`,
    `${colorPalette}.300`
  ])

  const barHeight = size === 'normal' ? '3' : '5'
  const total = totalEntities(progresses)

  if (total === 0) {
    return null
  }

  const { finished = 0, reviewing = 0, started = 0, not_started: notStarted = 0 } = progresses

  if (!finished && !notStarted) {
    return null
  }

  const finishedBar = finished ? (
    <Tooltip key="finished" content="Done">
      <Box w={`${(finished / total) * 100.0}%`} h={barHeight} borderWidth={1} borderColor="white" bgColor={colors[0]} />
    </Tooltip>
  ) : null

  const reviewingBar = reviewing ? (
    <Tooltip key="reviewing" content="In Review">
      <Box
        w={`${(reviewing / total) * 100.0}%`}
        h={barHeight}
        borderWidth={1}
        borderColor="white"
        bgColor={colors[1]}
      />
    </Tooltip>
  ) : null

  const startedBar = started ? (
    <Tooltip key="started" content="Started">
      <Box w={`${(started / total) * 100.0}%`} h={barHeight} borderWidth={1} borderColor="white" bgColor={colors[2]} />
    </Tooltip>
  ) : null

  const notStartedBar = notStarted ? (
    <Tooltip key="notStarted" content="Not Started">
      <Box
        w={`${(notStarted / total) * 100.0}%`}
        h={barHeight}
        borderWidth={1}
        borderColor="white"
        bgColor="gray.300"
      />
    </Tooltip>
  ) : null

  const bars = [finishedBar, reviewingBar, startedBar, notStartedBar].filter((bar) => bar)

  bars.forEach((bar, index) => {
    if (index === 0) {
      bars[index] = cloneElement(
        bar,
        {},
        cloneElement(bars[index].props.children, {
          borderLeftRadius: 'md'
        })
      )
    }

    if (index === bars.length - 1) {
      bars[index] = cloneElement(
        bar,
        {},
        cloneElement(bars[index].props.children, {
          borderRightRadius: 'md'
        })
      )
    }
  })

  return <Flex w="100%">{bars.map((bar) => bar)}</Flex>
}

export default CardMultiStepProgress
