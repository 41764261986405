import { Box, HStack, IconButton } from '@chakra-ui/react'
import { Panel } from '@xyflow/react'
import type { FC } from 'react'
import { memo } from 'react'

import AutoLayoutButton from './nav/autoLayoutButton'
import ButtonWrapper from './nav/buttonWrapper'

import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from '@app/components/ui/menu'
import { Tooltip } from '@app/components/ui/tooltip'
import AiChatMenu from '@app/pages/maps/components/aiChat/components/aiChatMenu'
import AnalyzeVariance from '@app/pages/maps/components/map/analyzeVariance'
import CopyMapToDoubleLoopButton from '@app/pages/maps/components/map/copyMapToDoubleLoopButton'
import MapCardFilters from '@app/pages/maps/components/map/filters/cardFilters/mapCardFilters'
import MapDateFilters from '@app/pages/maps/components/map/filters/dateFilters/mapDateFilters'
import MapSegmentFilters from '@app/pages/maps/components/map/filters/segmentFilters/mapSegmentFilters'
import MapExpandAllNodes from '@app/pages/maps/components/map/mapExpandAllNodes'
import MapStatDisplay from '@app/pages/maps/components/map/mapStatDisplay'
import ZoomButtons from '@app/pages/maps/components/map/nav/zoomButtons'
import ToggleMetricData from '@app/pages/maps/components/map/toggleMetricData'
import { useStrategyMapContext } from '@app/pages/maps/strategyMapContext'
import Can from '@app/shared/authorization/can'
import IconMap from '@app/utils/iconMap'

type Props = {
  strategyId: string
}

const MapFiltering: FC<Props> = ({ strategyId }) => {
  const { canEdit } = useStrategyMapContext()

  const extraOptionsMenu = (
    <MenuRoot closeOnSelect={false}>
      <MenuTrigger asChild>
        <IconButton variant="ghost" size="2xs" bgColor="bg.subtle">
          <IconMap.Ellipsis />
        </IconButton>
      </MenuTrigger>
      <MenuContent>
        <MenuItem value="relayout">
          <AutoLayoutButton strategyId={strategyId} />
        </MenuItem>
        <Can I="view" an="admin">
          <MenuItem value="copy-to-doubleloop">
            <CopyMapToDoubleLoopButton strategyId={strategyId} />
          </MenuItem>
        </Can>
        <MenuItem value="toggle-metric-data">
          <ToggleMetricData strategyId={strategyId} />
        </MenuItem>
      </MenuContent>
    </MenuRoot>
  )

  return (
    <Panel position="bottom-right">
      <ButtonWrapper>
        <HStack px={1} py={1}>
          <Tooltip content="AI Assistants" showArrow>
            <Box>
              <AiChatMenu strategyId={strategyId} />
            </Box>
          </Tooltip>
          <Can I="view" a="beta">
            <AnalyzeVariance strategyId={strategyId} />
          </Can>
          <MapSegmentFilters strategyId={strategyId} canEdit={canEdit} />
          <MapCardFilters canEdit={canEdit} />
          <MapExpandAllNodes />
          <MapStatDisplay strategyId={strategyId} canEdit={canEdit} />
          <MapDateFilters strategyId={strategyId} canEdit={canEdit} />
          <ZoomButtons />
          {extraOptionsMenu}
        </HStack>
      </ButtonWrapper>
    </Panel>
  )
}

MapFiltering.displayName = 'MapFiltering'

export default memo(MapFiltering)
