import { Box } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import type { FC } from 'react'
import ReactJson from 'react-json-view'

import { Button } from '@app/components/ui/button'
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import { useMetricSourceActivityQuery } from '@graphql/queries'

interface Props {
  importId: string
  onOpenChange: (args: { open: boolean }) => void
  open: boolean
}

const MetricSourceActivityShow: FC<Props> = ({ importId, onOpenChange, open }) => {
  const [{ data }] = useMetricSourceActivityQuery({
    variables: { id: importId }
  })

  let parsed = {}
  try {
    parsed = JSON.parse(data?.metricSourceActivity?.payload || {})
  } catch (e) {
    Sentry.captureException(e)
    parsed = {}
  }
  const src = typeof parsed === 'object' ? parsed : { value: parsed }

  const handleClose = () => {
    onOpenChange({ open: false })
  }

  return (
    <DialogRoot onOpenChange={handleClose} open={open} placement="center" size="lg">
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Import Data</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          <Box overflow="auto" maxH="250px">
            <ReactJson displayDataTypes={false} enableClipboard={false} name={false} src={src} />
          </Box>
        </DialogBody>

        <DialogFooter>
          <Button onClick={handleClose}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  )
}

export default MetricSourceActivityShow
