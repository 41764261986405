import { HStack, Link as ChakraLink, Stack, StackSeparator, Text } from '@chakra-ui/react'
import type { RadioGroupValueChangeDetails } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import { Field } from '@app/components/ui/field'
import { Radio, RadioGroup } from '@app/components/ui/radio'
import { toaster } from '@app/components/ui/toaster'
import StrategyFormUrl from '@app/pages/maps/components/settings/components/strategyFormUrl'
import { useStore } from '@app/store'
import type { DomainStrategy } from '@app/types'
import { Routes } from '@app/utils/routeHelpers'
import { SharingTypesEnum } from '@graphql/queries'

const HelperText = () => (
  <HStack pt={2}>
    <Text>Configure how stakeholders can see this strategy.</Text>
    <ChakraLink colorPalette="blue" href={Routes.docsSharing} rel="noopener noreferrer" target="_blank">
      Learn more.
    </ChakraLink>
  </HStack>
)

type Props = {
  strategy: DomainStrategy
}

const StrategyFormAccess: FC<Props> = ({ strategy }) => {
  const updateObject = useStore.use.updateObject()
  const [sharing, setSharing] = useState<SharingTypesEnum>(strategy?.sharing || SharingTypesEnum.PrivateAccess)

  const handleChange = (e: RadioGroupValueChangeDetails) => {
    const value = e.value as SharingTypesEnum

    updateObject({ strategy: { id: strategy.id, sharing: value } }).then(() => {
      toaster.create({
        title: 'Updated map sharing',
        type: 'success',
        description: "Successfully updated your map's sharing settings."
      })
    })
    setSharing(value)
  }

  return (
    <Stack gap={4} separator={<StackSeparator />}>
      <Field label="Privacy settings" helperText={<HelperText />}>
        <RadioGroup name="sharing" value={sharing || SharingTypesEnum.PrivateAccess} onValueChange={handleChange}>
          <Stack direction={['column', 'column', 'row']} gap={3}>
            <Radio value={SharingTypesEnum.PrivateAccess}>Private</Radio>
            <Radio value={SharingTypesEnum.TokenAccess}>Private w/token access</Radio>
            <Radio value={SharingTypesEnum.PublicAccess}>Public</Radio>
          </Stack>
        </RadioGroup>
      </Field>
      <StrategyFormUrl strategy={strategy} sharing={sharing} />
    </Stack>
  )
}

export default StrategyFormAccess
