import { Icon, IconButton } from '@chakra-ui/react'
import { useCallback } from 'react'

import { Tooltip } from '@app/components/ui/tooltip'
import ICON_MAP from '@app/utils/iconMap'
import { useChatUpdateMutation } from '@graphql/queries'

interface TogglePrimaryChatButtonProps {
  chatId: string
  primary: boolean
}

const TogglePrimaryChatButton: React.FC<TogglePrimaryChatButtonProps> = ({ chatId, primary, ...rest }) => {
  const [, updateChat] = useChatUpdateMutation()

  const onTogglePrimary = useCallback(() => {
    if (chatId) {
      updateChat({ input: { chatId, primary: !primary } })
    }
  }, [chatId, primary, updateChat])

  const icon = primary ? <ICON_MAP.Favorited /> : <ICON_MAP.Favorite />

  return (
    <Tooltip
      aria-label="Toggle Primary"
      content={primary ? 'Unset as primary AI context' : 'Set as primary AI context'}
    >
      <IconButton aria-label="Toggle Primary" onClick={onTogglePrimary} variant="ghost" {...rest}>
        <Icon boxSize={4} color="yellow.500">
          {icon}
        </Icon>
      </IconButton>
    </Tooltip>
  )
}

export default TogglePrimaryChatButton
