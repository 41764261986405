import { createContext, type FC, type ReactNode, useContext, useMemo, useState, useCallback } from 'react'
import { memo } from 'react'

import { abilitiesFor } from '@app/shared/authorization/caslProvider'
import type { StrategyQueryStrategy } from '@app/types'
import type { User as UserType } from '@graphql/types'

interface Props {
  canEdit: boolean
  aiBuilderMode: boolean
  toggleAiBuilderMode: () => void
  children: ReactNode
}

type User = Pick<UserType, 'abilities'> & {
  organization?: Pick<UserType['organization'], 'id'>
}

interface PermissionsContextWrapperProps {
  strategy: StrategyQueryStrategy
  user?: User | null
  children: ReactNode
}

const StrategyMapContextProvider = createContext(undefined)

const StrategyMapContext: FC<Props> = memo(({ canEdit, aiBuilderMode, toggleAiBuilderMode, children }) => {
  const value = useMemo(
    () => ({
      canEdit,
      aiBuilderMode,
      toggleAiBuilderMode
    }),
    [canEdit, aiBuilderMode, toggleAiBuilderMode]
  )

  return <StrategyMapContextProvider.Provider value={value}>{children}</StrategyMapContextProvider.Provider>
})

const StrategyMapContextWrapper: FC<PermissionsContextWrapperProps> = ({ strategy, user = null, children }) => {
  const [aiBuilderMode, setAiBuilderMode] = useState(true)

  const toggleAiBuilderMode = useCallback(() => {
    setAiBuilderMode((prevMode) => !prevMode)
  }, [])

  const userInStrategyOrganization = user && user.organization?.id === strategy.organization.id
  const isAnonymousUser = user === null || !userInStrategyOrganization

  const canEdit =
    !isAnonymousUser && abilitiesFor(user).can('update', 'strategy') && !strategy.backgroundChangeInProgress

  return (
    <StrategyMapContext canEdit={canEdit} aiBuilderMode={aiBuilderMode} toggleAiBuilderMode={toggleAiBuilderMode}>
      {children}
    </StrategyMapContext>
  )
}

const useStrategyMapContext = () => {
  const context = useContext<Props>(StrategyMapContextProvider)

  if (context === undefined) {
    throw new Error('useStrategyMapContext must be used within StrategyMapContext.Provider')
  }

  return context
}

export { StrategyMapContext, useStrategyMapContext }

export default StrategyMapContextWrapper
