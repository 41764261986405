import { IconButton } from '@chakra-ui/react'
import { memo } from 'react'

import { Tooltip } from '@app/components/ui/tooltip'
import { useStrategyMapContext } from '@app/pages/maps/strategyMapContext'
import ICON_MAP from '@app/utils/iconMap'

const AiBuilderModeToggleButton = ({ ...buttonStyles }) => {
  const { aiBuilderMode, toggleAiBuilderMode } = useStrategyMapContext()

  return (
    <Tooltip content="Toggle AI Builder Mode" positioning={{ placement: 'right' }} showArrow>
      <IconButton
        {...buttonStyles}
        aria-label="Toggle AI Builder Mode"
        onClick={toggleAiBuilderMode}
        colorPalette={aiBuilderMode ? 'purple' : null}
        variant={aiBuilderMode ? 'solid' : 'ghost'}
      >
        <ICON_MAP.AiTools />
      </IconButton>
    </Tooltip>
  )
}

export default memo(AiBuilderModeToggleButton)
