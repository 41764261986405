import { IconButton, Tabs } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import { PopoverRoot, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow } from '@app/components/ui/popover'
import { Tooltip } from '@app/components/ui/tooltip'
import CardColor from '@app/pages/maps/components/nodes/components/toolbar/components/cardSettings/cardColor'
import CardFields from '@app/pages/maps/components/nodes/components/toolbar/components/cardSettings/cardFields'
import CardSize from '@app/pages/maps/components/nodes/components/toolbar/components/cardSettings/cardSize'
import { useStrategyMapContext } from '@app/pages/maps/strategyMapContext'
import type { DomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  node: DomainNode
}

const CardSettingsButton: FC<Props> = ({ node }) => {
  const [open, setOpen] = useState(false)

  const { canEdit } = useStrategyMapContext()

  if (!canEdit) {
    return null
  }

  const openDrawer = () => {
    setOpen(!open)
  }

  return (
    <PopoverRoot portalled onOpenChange={(e) => setOpen(e.open)} open={open}>
      <PopoverTrigger asChild>
        <span>
          <Tooltip content="Display options">
            <IconButton borderRadius="none" aria-label="settings" onClick={openDrawer} size="sm" variant="outline">
              <ICON_MAP.CardSettings />
            </IconButton>
          </Tooltip>
        </span>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Tabs.Root fitted variant="enclosed">
            <Tabs.List>
              <Tabs.Trigger value="size">Size</Tabs.Trigger>
              <Tabs.Trigger value="color">Color</Tabs.Trigger>
              <Tabs.Trigger value="fields">Fields</Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content value="size">
              <CardSize node={node} />
            </Tabs.Content>
            <Tabs.Content value="color">
              <CardColor node={node} />
            </Tabs.Content>
            <Tabs.Content value="fields">
              <CardFields node={node} />
            </Tabs.Content>
          </Tabs.Root>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  )
}

export default CardSettingsButton
