import { Input, Text, VisuallyHidden } from '@chakra-ui/react'
import type { ChangeEventHandler, FC } from 'react'
import { useRef } from 'react'
import { useEffect, useState } from 'react'
import { useAsyncValue } from 'react-router-dom'
import type { OperationResult } from 'urql'

import { deriveType } from '../helpers'

import QueryExistingBasicCardFields from '@app/pages/maps/components/addCards/queryExisting/queryExistingBasicCardFields'
import QueryExistingEntityFields from '@app/pages/maps/components/addCards/queryExisting/queryExistingEntityFields'
import QueryExistingMetricFields from '@app/pages/maps/components/addCards/queryExisting/queryExistingMetricFields'
import MapDrawerQueryForm from '@app/pages/maps/components/drawer/mapDrawerQueryForm'
import LabelsAutocomplete from '@app/shared/autocomplete/labels/labelsAutocomplete'
import withAwait from '@app/shared/withAwait'
import type { SourcesQuery } from '@graphql/queries'

const valueTransformer = (labels) => labels.map((lbl) => lbl.name).join(',')

interface Props {
  onChange: ChangeEventHandler<HTMLFormElement>
  type: string
  cardTypeId?: string | null
}

const QueryExistingCardForm: FC<Props> = ({ onChange, type, cardTypeId = null }) => {
  const { data: sourcesData } = useAsyncValue() as OperationResult<SourcesQuery>

  const [typeValue, setTypeValue] = useState(deriveType(type))
  const [labels, setLabels] = useState([])
  const labelsRef = useRef(null)

  const handleChange = (value) => {
    const newLabels = value.map((newLabel) => ({ id: newLabel.value, name: newLabel.label }))

    setLabels(newLabels)

    // This is slightly ugly, but we need to trigger the form change event on the hidden input
    onChange({
      preventDefault: () => {},
      target: labelsRef?.current,
      nativeEvent: undefined,
      currentTarget: undefined,
      bubbles: false,
      cancelable: false,
      defaultPrevented: false,
      eventPhase: 0,
      isTrusted: false,
      isDefaultPrevented: function (): boolean {
        throw new Error('Function not implemented.')
      },
      stopPropagation: function (): void {
        throw new Error('Function not implemented.')
      },
      isPropagationStopped: function (): boolean {
        throw new Error('Function not implemented.')
      },
      persist: function (): void {
        throw new Error('Function not implemented.')
      },
      timeStamp: 0,
      type: ''
    })

    return null
  }

  useEffect(() => {
    setTypeValue(deriveType(type))

    // Reset labels when type changes
    setLabels([])
  }, [type])

  const sourcesDataSources = sourcesData?.sources

  const customFields = () => {
    switch (typeValue) {
      case 'metric':
        return <QueryExistingMetricFields sources={sourcesDataSources.metric} type={type} />
      case 'entity':
        return <QueryExistingEntityFields sources={sourcesDataSources.entity} type={type} />
      case 'basicCard':
        return <QueryExistingBasicCardFields cardTypeId={cardTypeId} />
      default:
        return null
    }
  }

  return (
    <MapDrawerQueryForm data-testid="add-existing-form" onChange={onChange}>
      <Text color="subtle" fontSize="sm">
        Select cards from the library
      </Text>
      {customFields()}
      <LabelsAutocomplete placeholder="Filter by label" onChange={handleChange} defaultValue={labels} />
      <VisuallyHidden asChild>
        <Input ref={labelsRef} id="labels" name="labels" readOnly value={valueTransformer(labels)} />
      </VisuallyHidden>
    </MapDrawerQueryForm>
  )
}

export default withAwait(QueryExistingCardForm, 'integrationInfo')
