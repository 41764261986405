import { Box, IconButton, Spinner, type ButtonProps, For } from '@chakra-ui/react'
import { Position } from '@xyflow/react'
import { useState } from 'react'

import { Button } from '@app/components/ui/button'
import { PopoverArrow, PopoverBody, PopoverContent, PopoverRoot, PopoverTrigger } from '@app/components/ui/popover'
import SuggestConnectedMetricsButton from '@app/pages/maps/components/nodes/components/toolbar/components/suggestConnectedMetricsButton'
import IconMap from '@app/utils/iconMap'

export type DirectionType = 'upstream' | 'downstream'

const AiNodeMenu = ({ node, position }) => {
  // need separate state management on each one or it gets mad and only respects the last one.
  const [laggingLoader, setLaggingLoading] = useState(false)
  const [leadingLoader, setLeadingLoading] = useState(false)
  // const [deterministicMetricsLoader, setDeterministicMetricsLoading] = useState(false)
  const [deterministicMetricsLoader] = useState(false)
  const [open, setOpen] = useState(false)

  const offset = 23 * 2

  const aiButtonStyles: ButtonProps = {
    variant: 'ghost',
    w: 'full'
  }

  const agents = [
    {
      direction: 'upstream',
      aiAssistantSlug: 'suggest-business-output-metrics',
      buttonText: 'Suggest business output metrics',
      loaderStateFunc: setLaggingLoading
    },
    {
      direction: 'downstream',
      aiAssistantSlug: 'suggest-input-metrics',
      buttonText: 'Suggest input metrics',
      loaderStateFunc: setLeadingLoading
    }
    // {
    //   direction: 'upstream',
    //   aiAssistantSlug: 'suggest-deterministic-metrics',
    //   buttonText: 'Suggest mathematical breakdown',
    //   loaderStateFunc: setDeterministicMetricsLoading
    // }
  ]

  return (
    <PopoverRoot size={'lg'} positioning={{ placement: position }} open={open} onOpenChange={(e) => setOpen(e.open)}>
      <PopoverTrigger asChild>
        <IconButton
          aria-label="Add cards"
          size="xs"
          borderRadius="full"
          variant="surface"
          pos="absolute"
          top={
            position === Position.Top
              ? `-${offset}px` // Move top handle up by 2 pixels
              : position === Position.Bottom
                ? `calc(100% + ${offset}px)` // Move bottom handle down by 2 pixels
                : '50%' // Center for left and right handles
          }
          left={
            position === Position.Left
              ? `-${offset}px` // Move left handle left by 2 pixels
              : position === Position.Right
                ? `calc(100% + ${offset}px)` // Move right handle right by 2 pixels
                : '50%' // Center for top and bottom handles
          }
          transform="translate(-50%, -50%)" // Center the button
          display="flex"
          alignItems="center"
          justifyContent="center"
          colorPalette="purple"
        >
          {laggingLoader || leadingLoader || deterministicMetricsLoader ? <Spinner /> : <IconMap.AiTools />}
        </IconButton>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Box>
            <For each={agents}>
              {(item) => (
                <SuggestConnectedMetricsButton
                  key={`suggest-metrics-${item.direction}`}
                  node={node}
                  aiAssistantSlug={item.aiAssistantSlug}
                  direction={item.direction as DirectionType}
                  handle={position}
                >
                  {({ onClick, loading }) => {
                    item.loaderStateFunc(loading)

                    const closer: typeof onClick = (e) => {
                      onClick(e)
                      setOpen(false)
                    }

                    return (
                      <Button loading={loading} onClick={closer} {...aiButtonStyles}>
                        {item.buttonText}
                      </Button>
                    )
                  }}
                </SuggestConnectedMetricsButton>
              )}
            </For>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  )
}

export default AiNodeMenu
