import { Box, HStack, Separator, Spacer, Stack, Text } from '@chakra-ui/react'
import type { ConditionalValue } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'
import { Link, NavLink } from 'react-router-dom'

import Settings from './nav/settings'

import { Button } from '@app/components/ui/button'
import useGetObject from '@app/hooks/useGetObject'
import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import UserPresences from '@app/pages/maps/components/map/nav/userPresences'
import NavMobilePopover from '@app/pages/maps/components/map/navMobilePopover'
import PlaybookCreateButton from '@app/pages/maps/components/playbookCreateButton'
import SharingDialog from '@app/pages/maps/components/sharing/sharingDialog'
import { useDrawerContext } from '@app/pages/maps/drawerContext'
import { useStrategyMapContext } from '@app/pages/maps/strategyMapContext'
import Can from '@app/shared/authorization/can'
import FavoriteToggleButton from '@app/shared/favorites/favoriteToggleButton'
import { ToggleButton } from '@app/shared/layout/shells/components/toggleButton'
import ICON_MAP from '@app/utils/iconMap'
import type { Strategy } from '@graphql/types'

interface Props {
  strategyId: string
  embedded?: boolean
}

interface TitleProps {
  as?: typeof Link
  to?: string
}
const Nav: FC<Props> = ({ strategyId, embedded = false }) => {
  const { canEdit } = useStrategyMapContext()
  const isAnonymousUser = useIsAnonymousUser(strategyId)
  const { isDrawerOpen, onDrawerToggle } = useDrawerContext()
  const strategy = useGetObject(strategyId, 'strategy')

  if (!strategy) {
    return null
  }

  const { id, favoriteId } = strategy

  const stackSpacing = { base: 2, md: 4 }

  const viewButtonProps = {
    // as: NavLink,
    color: 'border.active',
    fontWeight: '500',
    _currentPage: { color: 'fg.muted', bg: 'bg.muted' },
    size: 'sm' as ConditionalValue<'sm' | 'md' | 'lg' | 'xl' | '2xl' | '2xs' | 'xs'>,
    spacing: { base: 3, md: 6 },
    variant: 'ghost' as ConditionalValue<'ghost' | 'outline' | 'solid' | 'subtle' | 'surface' | 'plain'>
  }

  const titleProps: TitleProps = {}

  if (!isAnonymousUser) {
    titleProps.as = Link
    titleProps.to = 'map/settings'
  }

  const DesktopNavBar = () => (
    <Stack direction={{ base: 'column', md: 'row' }}>
      {/*<Spacer />*/}
      <Button {...viewButtonProps} aria-label="Map canvas" asChild>
        <NavLink to="map">
          <ICON_MAP.StrategyMap /> Canvas
        </NavLink>
      </Button>
      <Can I="view" a="beta">
        <Button {...viewButtonProps} aria-label="AI dashboard" asChild>
          <NavLink to="dashboard">
            <ICON_MAP.StrategyReport /> AI reports
          </NavLink>
        </Button>
      </Can>
      {/*<Can I="view" a="beta">*/}
      {/*  <Button {...viewButtonProps} aria-label="Reports" asChild>*/}
      {/*    <NavLink to="map/reports">*/}
      {/*      <ICON_MAP.StrategyReport /> Reports*/}
      {/*    </NavLink>*/}
      {/*  </Button>*/}
      {/*</Can>*/}
      <Button {...viewButtonProps} aria-label="Key results" asChild>
        <NavLink to="key-results">
          <ICON_MAP.OKR /> Key results
        </NavLink>
      </Button>
      <Button {...viewButtonProps} aria-label="Changelog" asChild>
        <NavLink to="changelog">
          <ICON_MAP.Changelog /> Changelog
        </NavLink>
      </Button>
    </Stack>
  )

  return (
    <Box zIndex="modal" px={2} py={1} bg="bg" borderRadius="sm" shadow="sm">
      <HStack gap={stackSpacing}>
        <HStack flexGrow={0} gap={1}>
          <ToggleButton
            fontSize="lg"
            variant="ghost"
            open={isDrawerOpen}
            aria-label="Open Menu"
            onClick={onDrawerToggle}
            show={!isAnonymousUser}
          />
          <Text fontSize="sm" defaultValue={strategy?.name} {...titleProps}>
            {strategy?.name}
          </Text>
        </HStack>
        <Separator h="1.5rem" mx={2} orientation="vertical" />
        {!embedded && (
          <HStack flexGrow={1} flexShrink={0} hideBelow="md">
            <DesktopNavBar />
          </HStack>
        )}
        <Spacer />
        <UserPresences />
        <NavMobilePopover>
          <DesktopNavBar />
        </NavMobilePopover>
        <HStack gap={1} hideBelow="md">
          {/*  <IconButton */}
          {/*    {...viewButtonProps} */}
          {/*    aria-label="Map Education" */}
          {/*    colorPalette="gray" */}
          {/*    to={location.pathname.endsWith('/learn') ? location.pathname : `${location.pathname}/learn`} */}
          {/*    variant="ghost" */}
          {/*  ><Icon as={ICON_MAP.MapEducation} /></IconButton> */}
          <FavoriteToggleButton
            favoritableType="Strategy"
            favoritableId={id}
            favoriteId={favoriteId}
            iconBoxSize={5}
            show={!isAnonymousUser}
            colorPalette="gray"
          />
          <PlaybookCreateButton strategy={strategy} show={canEdit} />
          <Settings strategy={strategy as Strategy} show={canEdit} />
          <SharingDialog strategy={strategy as Strategy} show={canEdit} />
        </HStack>
      </HStack>
    </Box>
  )
}

export default memo(Nav)
