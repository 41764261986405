import { HStack, Spacer, Stack, Text, Textarea } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import bookmarkletJS from '@app/pages/maps/components/settings/components/bookmarklet'
import loadCredentials from '@app/routes/lib/loadCredentials'

const AmplitudeBulkCharts: FC = () => {
  const [urls, setUrls] = useState<string>('')
  const amplitudeCredentials = useGetObjectsByProperties('credential', { sourceName: 'MetricSources::Amplitude' })

  useEffect(() => {
    loadCredentials().then(() => {})
  }, [])

  if (!amplitudeCredentials?.length) {
    return null
  }

  const helperText = <Text>Drag and drop the "DL Bulk Import" bookmarklet below on to your bookmark bar.</Text>

  const handleTextareaInput = (e) => {
    setUrls(e.target.value)
  }

  const javascript = `javascript:(${encodeURIComponent(bookmarkletJS.toString())})();`

  return (
    <Stack>
      <Field label="Chart URLS" helperText={helperText}>
        <Textarea
          value={urls}
          name="urls"
          onInput={handleTextareaInput}
          placeholder="Add Amplitude chart URLs here to add to the map"
          autoresize
          minH="151px"
        />
      </Field>
      <HStack>
        <Button
          variant="outline"
          size="sm"
          dangerouslySetInnerHTML={{ __html: `<a href="${javascript}">DL Bulk Import</a>` }}
        ></Button>
        <Spacer />
      </HStack>
    </Stack>
  )
}

export default AmplitudeBulkCharts
