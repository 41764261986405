import { Heading, Separator, Stack, Text } from '@chakra-ui/react'
import { Panel } from '@xyflow/react'
import type { FC } from 'react'
import { memo } from 'react'
import { useSearchParams } from 'react-router-dom'

import useGetSelectedNodes from '@app/hooks/useGetSelectedNodes'
import { useStrategyMapContext } from '@app/pages/maps/strategyMapContext'

type Props = {
  strategyId: string
}

const MapDebugger: FC<Props> = ({ strategyId }) => {
  const [searchParams] = useSearchParams()
  const debugging = searchParams.get('debug')
  const selectedNodes = useGetSelectedNodes(strategyId)

  const { canEdit } = useStrategyMapContext()

  if (!debugging) {
    return null
  }

  return (
    <Panel position="top-right" style={{ marginTop: '50px' }}>
      <Stack
        p={4}
        borderRadius="sm"
        shadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);"
        bgColor="bg"
      >
        <Heading size="xs">Debug Menu</Heading>
        <Separator />
        <Text>canEdit: {canEdit.toString()}</Text>
        <Text>
          Selected Nodes: {selectedNodes.length} - {selectedNodes.map((n) => `${n.id}, `)}
        </Text>
      </Stack>
    </Panel>
  )
}

MapDebugger.displayName = 'MapDebugger'

export default memo(MapDebugger)
