import { Box, Group, HStack, IconButton } from '@chakra-ui/react'
import type { StackProps } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import CollapsedBasicCard from './collapsedCards/collapsedBasicCard'
import CollapsedEntity from './collapsedCards/collapsedEntity'
import CollapsedMetric from './collapsedCards/collapsedMetric'
import CollapsedSection from './collapsedCards/collapsedSection'
import CollapsedStrategy from './collapsedCards/collapsedStrategy'

import { useColorModeValue } from '@app/components/ui/color-mode'
import { Tooltip } from '@app/components/ui/tooltip'
import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import DeleteButton from '@app/pages/maps/components/nodes/components/toolbar/components/deleteButton'
import { useStrategyMapContext } from '@app/pages/maps/strategyMapContext'
import { useStore } from '@app/store'
import type {
  CardSize,
  DomainBasicCard,
  DomainEntity,
  DomainMetric,
  DomainNode,
  DomainObject,
  DomainSection,
  DomainStrategy
} from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

interface Props extends StackProps {
  node: DomainNode
  nodeObject: DomainObject
  size?: CardSize
}

const CollapsedNode: FC<Props> = ({ node, nodeObject, size = 'normal', ...rest }) => {
  const [isHovering, setIsHovering] = useState(false)
  const { strategyId } = node
  const isAnonymousUser = useIsAnonymousUser(strategyId)

  const { canEdit } = useStrategyMapContext()

  const expandNode = useStore.use.expandNode()
  const route = `/strategy/${strategyId}/map/${node.type}/${nodeObject.id}`
  const navHighlightColor = useColorModeValue('blue.50', 'blue.800')

  let collapsedNavProps = {}
  let editButton = null
  if (!['strategy', 'section'].includes(node.type)) {
    collapsedNavProps = {
      as: NavLink,
      to: route,
      _currentPage: { bg: navHighlightColor }
    }

    editButton = (
      <Tooltip content="Edit">
        <IconButton aria-label="Edit card" asChild size="xs" variant="ghost">
          <Link to={`${route}/settings`}>
            <ICON_MAP.Edit />
          </Link>
        </IconButton>
      </Tooltip>
    )
  }

  // if the node.type is strategy, set the collapsedNavProps to a link and route to `preview/${id}`
  if (node.type === 'strategy') {
    collapsedNavProps = {
      as: NavLink,
      to: `preview/${nodeObject.id}`,
      _currentPage: { bg: navHighlightColor }
    }
  }

  const { type } = node

  let cardDisplay
  switch (type) {
    case 'metric':
      cardDisplay = <CollapsedMetric strategyId={strategyId} metric={nodeObject as DomainMetric} size={size} />
      break
    case 'entity':
      cardDisplay = <CollapsedEntity entity={nodeObject as DomainEntity} size={size} />
      break
    case 'section':
      cardDisplay = <CollapsedSection section={nodeObject as DomainSection} size={size} />
      break
    case 'strategy':
      cardDisplay = <CollapsedStrategy strategy={nodeObject as DomainStrategy} size={size} />
      break
    case 'basicCard':
      cardDisplay = <CollapsedBasicCard basicCard={nodeObject as DomainBasicCard} size={size} />
      break
    default:
  }

  // This needs a better short circuit so anonymous users do not end up in a bad place.
  if (isAnonymousUser) {
    collapsedNavProps = {}
  }

  return (
    <>
      <HStack
        key={`collapsed-node-row-${node.id}`}
        pos="relative"
        px={2}
        py={2}
        _hover={{ cursor: 'pointer', bgColor: 'bg.subtle' }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        {...rest}
        {...collapsedNavProps}
      >
        <Box w="100%">{cardDisplay}</Box>
        <Group
          pos="absolute"
          top={-2}
          right={-2}
          gap={0}
          borderRadius="sm"
          shadow="xs"
          visibility={canEdit && isHovering ? 'initial' : 'hidden'}
          attached
          bgColor="bg"
        >
          {editButton}
          <Tooltip content="Uncollapse card">
            <IconButton
              aria-label="Uncollapse card"
              onClick={() => expandNode(strategyId, node)}
              size="xs"
              variant="ghost"
            >
              <ICON_MAP.ExpandAction />
            </IconButton>
          </Tooltip>
          <DeleteButton node={node} variant="ghost" size="xs" />
        </Group>
      </HStack>
    </>
  )
}

export default CollapsedNode
