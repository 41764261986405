import capitalize from 'lodash/capitalize'
import type { FC } from 'react'

import { Tag } from '@app/components/ui/tag'
import type { CardSize } from '@app/types'
import { COLOR_MAP } from '@app/utils/iconMap'
import type { EntityWorkflowStateEnum } from '@graphql/types'

const backgroundColors: Record<EntityWorkflowStateEnum, string> = {
  not_started: `${COLOR_MAP.WorkflowStateUnstarted}.500`,
  started: `${COLOR_MAP.WorkflowStateStarted}.500`,
  reviewing: `${COLOR_MAP.WorkflowStateReviewing}.500`,
  finished: `${COLOR_MAP.WorkflowStateFinished}.600`
}

const statusDisplay = (status = '', workflowState = null) => {
  const displayStatus = capitalize(status).replace(/_/g, ' ')
  const backgroundColor = backgroundColors[workflowState] || backgroundColors.not_started

  return { displayStatus, backgroundColor, textColor: 'white' }
}

interface Props {
  status: string | null
  workflowState: EntityWorkflowStateEnum | null
  bgColor?: string
  color?: string
  size?: CardSize
}

const CardEntityStatus: FC<Props> = ({ status, workflowState, bgColor = null, color = null, size = 'normal' }) => {
  if (!status) {
    return null
  }

  const { displayStatus, backgroundColor, textColor } = statusDisplay(status, workflowState)
  const fontSize = size === 'large' ? 'md' : 'sm'

  return (
    <Tag color={color || textColor} fontSize={fontSize} bgColor={bgColor || backgroundColor}>
      {displayStatus}
    </Tag>
  )
}

export default CardEntityStatus
